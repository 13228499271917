.container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: absolute;
  max-width: 100%;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-family: 'Monorama-Regular', monospace;
}

.container strong {
  font-size: 20px;
  line-height: 26px;
  font-family: 'Monorama-Regular', monospace;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
  font-family: 'Monorama-Regular', monospace;
}

.container a {
  text-decoration: none;
  font-family: 'Monorama-Regular', monospace;
}
