/* Center the card container */
.ion-justify-content-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.card-container {
    width: 400px; /* Adjust the width as needed */
    height: 600px; /* Adjust the height as needed */
    perspective: 1000px;
    position: relative;
}

.card-front, .card-back {
    position: absolute;
    backface-visibility: hidden;
    transition: transform 0.6s;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

.card-front {
    z-index: 2;
}

.card-container.flipped .card-front {
    transform: rotateY(180deg);
}

.card-back {
    transform: rotateY(-180deg);
}

.card-container.flipped .card-back {
    z-index: 3;
    transform: rotateY(0deg);
}

/* Make sure the card is not too big for any screen */
.card-front img, .card-back img {
    max-width: 100%;
    max-height: 100%;
}
